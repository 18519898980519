<template>
	<v-container id="error-view" class="fill-height text-center" tag="section">
		<v-row justify="center" align="center" class="fill-height">
			<v-col cols="12" sm="8" md="8" lg="6" xl="4">
				<v-card elevation="0" class="pa-5 bg-fade">
					<v-card-title class="text-h4">
						<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo_small.png" transition="scale-transition" width="40"/>
						rewind
					</v-card-title>
					<v-card-text>
						<v-form ref="form" lazy-validation>
							<v-text-field v-model="credentials.email" :rules="emailRules" label="email" required></v-text-field>
							<v-text-field v-model="credentials.password" :rules="passwordRules" label="password" required :type="showPassword ? 'text' : 'password'" append-icon="mdi-eye" @click:append="showPassword = !showPassword"></v-text-field>
							<v-card-actions color="transparent">
								<v-spacer></v-spacer>
								<v-btn @click="login" rounded color="accent">login</v-btn>
							</v-card-actions>
						</v-form>
					</v-card-text>
					<v-card-actions class="flex-wrap flex-column flex-sm-row">
						<v-btn text rounded :to="{name:'ForgotPassword'}">forgot password</v-btn>
						<v-spacer></v-spacer>
						<v-btn text rounded :to="{name:'SignUp'}">create account</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
<!--			<v-col cols="12">-->
<!--				<p class="text-center">v01.00.03</p>-->
<!--			</v-col>-->
		</v-row>
	</v-container>
</template>

<script>
import {get} from "vuex-pathify";
import {toast} from '@/utils/helpers'

export default {
	name: 'Login',
	metaInfo: {
		title: 'Login',
	},
	computed: {
		user: get('user/user'),
		token: get('user/token')
	},
	data: () => ({
		showPassword: false,
		credentials: {
			email: '',
			password: '',
		},
		emailRules: [
			v => !!v || 'email is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
		],
		passwordRules: [
			v => !!v || 'password is required',
			v => v.length >= 8 || 'password must be at least 8 characters',
		],
	}),
	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.$api.sessions.create(this.credentials)
					.then(response => {
						this.$store.set('user/user', response.data.user)
						this.$store.set('user/token', response.data.token)
						// localStorage.setItem('token', response.data.token)
						this.$router.push({name: 'Home'})
					})
					.catch(error => {
						if (error.response?.status === 401) {
							toast.add("Not authorized", 'error')
						} else {
							toast.handleResponseErrors(error.response.data.error)
						}
						console.log(error.response.status);
					})
			}
		}
	}
}
</script>